<template>
  <v-dialog v-model="state" persistent width="500px">
    <v-card>
      <v-card-title>
        <span class=" font-weight-bold font-size-lg blue-grey--text font"
          >Edit Customer</span
        >
      </v-card-title>
      <ValidationObserver
        tag="div"
        class="d-flex flex-row flex-grow-1"
        v-slot="{ handleSubmit }"
      >
        <form
          class="d-flex flex-column flex-grow-1"
          @submit.prevent="handleSubmit(editCustomer)"
        >
          <v-card-text>
            <ValidationObserver
              class="d-flex flex-column flex-grow-1"
              v-slot="{ handleSubmit }"
            >
              <form @submit.prevent="handleSubmit(addAsIndividual)">
                <ValidationProvider
                  tag="div"
                  class="font d-flex flex-row flex-grow-1"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >people</i
                  >
                  <v-text-field
                    autofocus
                    class="font font-weight-medium d-flex flex-row flex-grow-1"
                    label="Customer Name"
                    clearable
                    :error-messages="errors[0]"
                    v-model="individual.name"
                    hint="Enter customer full name"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="font d-flex flex-row flex-grow-1"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >email</i
                  >
                  <v-text-field
                    class="font font-weight-medium d-flex flex-row flex-grow-1"
                    label=" Email"
                    clearable
                    :error-messages="errors[0]"
                    v-model="individual.email"
                    hint="Enter  email here"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="font d-flex flex-row flex-grow-1"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >phone</i
                  >
                  <v-text-field
                    autofocus
                    class="font font-weight-medium d-flex flex-row flex-grow-1"
                    label="Phone Number"
                    clearable
                    :error-messages="errors[0]"
                    v-model="individual.phone"
                    hint="Enter phone number here"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="font d-flex flex-row flex-grow-1"
                  rules="required|decimals"
                  v-slot="{ errors }"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >money</i
                  >
                  <v-text-field
                    autofocus
                    class="font font-weight-medium d-flex flex-row flex-grow-1"
                    label="Balance/Amount"
                    clearable
                    :error-messages="errors[0]"
                    v-model="individual.amount"
                    hint="Enter balance or customer amount here"
                  />
                </ValidationProvider>

                <div class="d-flex flex-row flex-grow-1 mt-3">
                  <ValidationProvider
                    tag="div"
                    class="font d-flex flex-row flex-grow-1"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <i
                      :class="errors.length > 0 ? 'red--text' : 'black--text'"
                      class="material-icons-outlined mt-4 mr-3"
                      >credit_card</i
                    >
                    <v-autocomplete
                      :items="cards"
                      class="  font font-weight-medium font-size-sm"
                      item-value="id"
                      v-model="individual.cardId"
                      label="Card Type"
                      item-text="name"
                      hint="Choose ID Card type ..."
                    >
                      <template #item="{item}">
                        <span class="font font-weight-medium font-size-sm">{{
                          item.name
                        }}</span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="font d-flex flex-row flex-grow-1"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <i
                      :class="errors.length > 0 ? 'red--text' : 'black--text'"
                      class="material-icons-outlined mt-4 mr-3"
                      >map</i
                    >
                    <v-text-field
                      class="font font-weight-medium d-flex flex-row flex-grow-1"
                      label="ID Card Nuber"
                      clearable
                      :error-messages="errors[0]"
                      v-model="individual.cardNumber"
                      hint="Enter card number here"
                    />
                  </ValidationProvider>
                </div>

                <div class="d-flex flex-row flex-grow-1">
                  <ValidationProvider
                    tag="div"
                    class="font d-flex flex-row flex-grow-1"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <i
                      :class="errors.length > 0 ? 'red--text' : 'black--text'"
                      class="material-icons-outlined mt-4 mr-3"
                      >location_on</i
                    >
                    <v-textarea
                      v-model="individual.residentAddress"
                      class="font font-weight-medium d-flex flex-row flex-grow-1"
                      label="Resident Address"
                      clearable
                      :error-messages="errors[0]"
                      hint="Enter resident address here"
                    />
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close" class="font font-weight-medium">
              <span class="font font-weight-medium font-size-sm"> Cancel</span>
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="loading || detailsLoading"
              :loading="loading"
              class="font font-weight-medium"
            >
              <span class="font font-weight-medium font-size-sm"> Save</span>
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { decimals } from "../../../utils/resolvers";
extend("required", {
  ...required,
  message: `Input field is required`
});
extend("decimals", decimals);
export default {
  name: "EditIndividualCustomer",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: Boolean,
    loading: Boolean,
    currency: {
      type: Array,
      default: () => []
    },
    cards: {
      type: Array,
      default: () => []
    },
    details: {
      type: Object,
      default: null
    },
    detailsLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    individual: {
      name: "",
      email: "",
      phone: "",
      residentAddress: "",
      cardId: "",
      cardNumber: "",
      amount: 0
    }
  }),
  watch: {
    details(payload) {
      this.individual = { ...payload, cardId: payload.cardId?.id };
    }
  },
  methods: {
    close() {
      this.$emit("close", "add");
    },
    editCustomer() {
      this.$emit("editCustomer", this.individual);
    }
  }
};
</script>
