var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:" font-weight-bold font-size-lg blue-grey--text font"},[_vm._v("Edit Customer")])]),_c('ValidationObserver',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"d-flex flex-column flex-grow-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editCustomer)}}},[_c('v-card-text',[_c('ValidationObserver',{staticClass:"d-flex flex-column flex-grow-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addAsIndividual)}}},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("people")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"autofocus":"","label":"Customer Name","clearable":"","error-messages":errors[0],"hint":"Enter customer full name"},model:{value:(_vm.individual.name),callback:function ($$v) {_vm.$set(_vm.individual, "name", $$v)},expression:"individual.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("email")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":" Email","clearable":"","error-messages":errors[0],"hint":"Enter  email here"},model:{value:(_vm.individual.email),callback:function ($$v) {_vm.$set(_vm.individual, "email", $$v)},expression:"individual.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("phone")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"autofocus":"","label":"Phone Number","clearable":"","error-messages":errors[0],"hint":"Enter phone number here"},model:{value:(_vm.individual.phone),callback:function ($$v) {_vm.$set(_vm.individual, "phone", $$v)},expression:"individual.phone"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("money")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"autofocus":"","label":"Balance/Amount","clearable":"","error-messages":errors[0],"hint":"Enter balance or customer amount here"},model:{value:(_vm.individual.amount),callback:function ($$v) {_vm.$set(_vm.individual, "amount", $$v)},expression:"individual.amount"}})]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-3"},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("credit_card")]),_c('v-autocomplete',{staticClass:"  font font-weight-medium font-size-sm",attrs:{"items":_vm.cards,"item-value":"id","label":"Card Type","item-text":"name","hint":"Choose ID Card type ..."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(_vm.individual.cardId),callback:function ($$v) {_vm.$set(_vm.individual, "cardId", $$v)},expression:"individual.cardId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("map")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"ID Card Nuber","clearable":"","error-messages":errors[0],"hint":"Enter card number here"},model:{value:(_vm.individual.cardNumber),callback:function ($$v) {_vm.$set(_vm.individual, "cardNumber", $$v)},expression:"individual.cardNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("location_on")]),_c('v-textarea',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"Resident Address","clearable":"","error-messages":errors[0],"hint":"Enter resident address here"},model:{value:(_vm.individual.residentAddress),callback:function ($$v) {_vm.$set(_vm.individual, "residentAddress", $$v)},expression:"individual.residentAddress"}})]}}],null,true)})],1)],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font font-weight-medium",attrs:{"text":""},on:{"click":_vm.close}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(" Cancel")])]),_c('v-btn',{staticClass:"font font-weight-medium",attrs:{"color":"primary","type":"submit","disabled":_vm.loading || _vm.detailsLoading,"loading":_vm.loading}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v(" Save")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }